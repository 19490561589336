import Day from "./components/Day/Day.vue";
import Telegram from "./components/Telegram/Telegram.vue";
import ShoutOut from "./components/ShoutOut/ShoutOut.vue";
import Matrix from "./components/Matrix/Matrix.vue";
import HK from "./components/FTSE/FTSE.vue";
import Movers from "./components/Movers/Movers.vue";
import New from "./components/New/New.vue";
import Expired from "./components/Expired/Expired.vue";
export default {
    name: "LandingPage",
    created () {
        this.$nextTick(function () {
            // localStorage.clear()
            this.getBannerDate();
            this.getShoutQutDate();
            this.getRiclist();
            this.getDayDate()
            this.getFocusDate();
            this.InputIncident();
            this.enterSeekAssignment();
            this.tooltipShowHide()
            this.navArrows()
            $('.banner-text-n').click(function (e) {
                e = e || window.event;
                if (e.stopPropagation) { //W3C阻止冒泡方法  
                    e.stopPropagation();
                } else {
                    e.cancelBubble = true; //IE阻止冒泡方法  
                }
            })
            if (JSON.parse(localStorage.getItem("rhbHome"))) {
                this.gadgetDataShow = JSON.parse(localStorage.getItem("rhbHome"))
            }
            //改变meta的keywords
            $('meta[name="keywords"]').attr('content', "Malaysia warrants, structured warrants, RHB, Structured warrants, Warrants, Warrant, Hong Kong warrants, Search Warrant, Search Underlying, Daily Warrant");
        });
    },
    mounted () {
        this.$nextTick(function () {

        })
    },
    data () {
        return {
            shoutQutLiOne: [],
            judgeLi: null,
            valueLi: null,
            dsplay_nmll: null,
            promote_img: null,
            promote_http: null,
            banner_if: false,
            dayDataArr: [],
            dayDataArrIf: true,
            tooltipArr: [],
            ricM: [],
            JoinRIC: null,
            ifClick: false,
            telegram: null,
            Focus: [],
            navMenu: [{
                name: 'Warrant Search',
                icon: require('../../../public/image/Warrant_Search.png'),
                to: '/my/WarrantSearch'
            },
            {
                name: 'Warrant Matrix',
                icon: require('../../../public/image/Warrant_Matrix.png'),
                to: '/my/WarrantMatrix'
            },
            {
                name: 'Underlying Chart',
                icon: require('../../../public/image/Underlying_Chart.png'),
                to: '/my/UnderlyingChart'
            },
            {
                name: 'Warrant in Focus',
                icon: require('../../../public/image/Warrant_in_Focus.png'),
                to: '/my/WarrantInFocus'
            },
            {
                name: 'Warrant Documents',
                icon: require('../../../public/image/Warrant_Documents.png'),
                to: '/my/Document'
            },
            ],
            gadgetDataShow: [{
                name: 'Warrants of the Day',
                id: "day"
            },
            {
                name: 'RHB Warrant Telegram',
                id: "telegram"
            },
            {
                name: 'Warrant Matrix',
                id: "matrix"
            },
            ],
            gadgetData: [{
                name: 'Warrants of the Day',
                id: "day"
            },
            {
                name: 'RHB Warrant Telegram',
                id: "telegram"
            },
            {
                name: 'Warrant Matrix',
                id: "matrix"
            },
            {
                name: 'Warrant Shout-Out',
                id: "shoutOut"
            },
            {
                name: 'HK Warrants',
                id: "hk"
            },
            {
                name: 'Warrant Movers',
                id: "movers"
            },
            {
                name: 'New Warrants',
                id: "new"
            },
            {
                name: 'Expiring Warrants ',
                id: "expiring"
            },
            ],
            navArrowsIf: false,
            navPlaceIf: 1,
            num: 0
        };
    },
    methods: {
        getBannerDate: function () {
            let then = this;
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedLandingBanner",
                data: { token: "webkey" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        if (data.promote.length === 1) {
                            then.getPromote(data.promote)
                        }
                        then.DeployedLandingBanner = data.text[0];
                        then.assignmentBanner(data.text[0]);
                        then.telegram = data.telegram
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        assignmentBanner: function (data) {
            var bannerDesktopImg, bannerMobileImg, barrerDesktopWebp, barrerMobileWebp;
            if (data.desktop_image_path_webp || data.mobile_image_path_webp) {
                this.banner_if = true
            } else {
                this.banner_if = false
            }
            if (
                data.desktop_image_path !== "" &&
                data.desktop_image_path !== undefined
            ) {
                bannerDesktopImg = data.desktop_image_path;
            } else {
                bannerDesktopImg = data.mobile_image_path;
            }
            if (
                data.mobile_image_path !== "" &&
                data.mobile_image_path !== undefined
            ) {
                bannerMobileImg = data.mobile_image_path;
            } else {
                bannerMobileImg = data.desktop_image_path;
            }
            if (
                data.desktop_image_path_webp !== "" &&
                data.desktop_image_path_webp !== undefined
            ) {
                barrerDesktopWebp = data.desktop_image_path_webp;
            } else {
                barrerDesktopWebp = data.mobile_image_path_webp;
            }
            if (
                data.mobile_image_path_webp !== "" &&
                data.mobile_image_path_webp !== undefined
            ) {
                barrerMobileWebp = data.mobile_image_path_webp;
            } else {
                barrerMobileWebp = data.desktop_image_path_webp;
            }
            var windowWidth = $(window).width();
            if (windowWidth < 768 || windowWidth === 768) {
                $(".banner-img").attr("src", bannerMobileImg);
                $(".banner-source").attr("src", barrerMobileWebp);
            } else {
                $(".banner-img").attr("src", bannerDesktopImg);
                $(".banner-source").attr("src", barrerDesktopWebp);
            }
            $(window).resize(function () {
                windowWidth = $(window).width();
                if (windowWidth < 768 || windowWidth === 768) {
                    $(".banner-img").attr("src", bannerMobileImg);
                    $(".banner-source").attr("src", barrerMobileWebp);
                } else {
                    $(".banner-img").attr("src", bannerDesktopImg);
                    $(".banner-source").attr("src", barrerDesktopWebp);
                }
            });
            if (data.title && data.content_color) {
                $(".banner-text-h")
                    .css({
                        color: data.title_color
                    })
                    .find(".normal_title-n")
                    .html(data.title);
                $(".banner-text-c")
                    .html(data.content)
                    .css({ color: data.content_color });
            } else {
                $(".banner-text-h")
                    .find(".normal_title-n")
                    .html("RHB Warrant welcomes you.")
                    .css({
                        visibility: "hidden"
                    });
                $(".banner-text-c")
                    .html("Your One-Stop Structured Warrant Portal.")
                    .css({
                        visibility: "hidden"
                    });
            }
            if (data.url) {
                $(".carousel-caption_box").click(function () {
                    window.open(data.url);
                });
                $('.carousel-caption_box').css({
                    'cursor': 'pointer'
                })
            }

        },
        getShoutQutDate: function () {
            let then = this;
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedAnnouncement",
                data: { token: "webkey", limit: '5' },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        then.assignmentShoutQut(data.text);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        assignmentShoutQut: function (data) {
            for (var s = 0, max = data.length; s < max; s++) {
                if (s < 3) {
                    this.shoutQutLiOne.push(data);
                }
            }
        },


        getRiclist: function () {
            let then = this;
            /*获取ric list*/
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        then.matrixCombobox(data.ric);
                        then.getRic(data.ric);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        getRic: function (ric) {
            this.JoinRIC = ric;
            let then = this;
            /*获取ric list*/
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetScreenerData",
                data: { token: "webkey", init: "1" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        $.each(data.underlyings, function (index, value) {
                            then.JoinRIC.unshift({
                                ticker: value.underlying_code,
                                underlying_name: value.underlying_name,
                                dsply_nmll: value.underlying_ticker,
                                ric: value.underlying_ric,
                                judge: 'true'
                            });
                        });
                        then.matrixInput(then.JoinRIC);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        tooltipShowHide () {
            $('.banner-text-n').mouseover(function () {
                if (!$('#matrix_input_0').val()) {
                    $('.tooltipUl').show()
                }
            }).mouseout(function () {
                $('.tooltipUl').hide()
            })
        },
        matrixAssignment: function (date, v, liDox, inputDox) {
            let then = this
            $("." + liDox).empty();
            var matrixLi = "";
            $.each(date, function (index, value) {
                matrixLi =
                    "<li>" +
                    "<span>" +
                    value.liText +
                    "</span>" +
                    '<i class="i_one">' +
                    value.ric +
                    "</i>" +
                    '<i class="i_two">' +
                    value.judge +
                    "</i>" +
                    '<i class="i_three">' +
                    value.name +
                    "</i>" +
                    "</li>";
                $("." + liDox).append(matrixLi);
            });
            if (date.length == 0) {
                var nomatxiLi =
                    "<li>No results found. Please try another keyword.</li>";
                $("." + liDox).append(nomatxiLi);
            }
            $("." + liDox)
                .find("li")
                .click(function () {
                    then.dsplay_nmll = $(this)
                        .find(".i_three")
                        .text();
                    then.judgeLi = $(this)
                        .find(".i_two")
                        .text();
                    then.valueLi = $(this)
                        .find(".i_one")
                        .text();
                    $("#" + inputDox).val(
                        $(this)
                            .find("span")
                            .text()
                    );
                    $("." + liDox).empty();
                    // $("." + liDox).css({ height: "0" });
                    // $("." + liDox).hide();
                    then.ifClick = true
                })
        },
        enterSeekAssignment: function () {
            let then = this
            $(document).keyup(function (event) {
                if (event.keyCode == 13) {
                    then.seekAssignment()
                }
            });
        },
        // 获取 Warrants of the day 数据
        getDayDate: function () {
            let then = this;
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedWarrantOfTheDay",
                data: { token: "webkey" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        $.each(data.text[0], function (index, value) {
                            if (index.indexOf(index.replace(/ric/gi, "")) > 0) {
                                then.ricM[Number(index.replace(/[^0-9]/gi, "")) - 1] = value;
                            }
                        });
                        then.getDayDetailData(then.ricM);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 Warrants of the day 详细数据
        getDayDetailData: function (ric) {
            var $dayData = $(".index-content-centre-data-box");
            let _this = this;
            if (ric) {
                $.ajax({
                    type: "GET",
                    url: this.$js.webservice + "GetMarketData",
                    data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                    dataType: "json",
                    success: (data) => {
                        if (data.status === "SUCCESS") {
                            let arrSun = [],
                                judgeP, left_num, judgeUp, right_num, BID_PCTCHNG, underlyingbid_pctchng
                            this.tooltipRic(data)
                            $.each(ric, function (index, value) {
                                BID_PCTCHNG = data[value].BID_PCTCHNG;
                                underlyingbid_pctchng = data[value].underlyingbid_pctchng;
                                if (BID_PCTCHNG.indexOf("+") > -1) {
                                    BID_PCTCHNG = _this.$js.formatNumber(BID_PCTCHNG, 1, 0);
                                } else if (BID_PCTCHNG.indexOf("-") > -1) {
                                    BID_PCTCHNG = Math.abs(
                                        _this.$js.formatNumber(BID_PCTCHNG, 1, 0)
                                    );
                                } else if (
                                    BID_PCTCHNG.indexOf("-") < 0 ||
                                    BID_PCTCHNG.indexOf("+") < 0
                                ) {
                                    BID_PCTCHNG = _this.$js.formatNumber(BID_PCTCHNG, 1, 0);
                                }
                                if (underlyingbid_pctchng.indexOf("+") > -1) {
                                    underlyingbid_pctchng = _this.$js.formatNumber(
                                        underlyingbid_pctchng,
                                        1,
                                        0
                                    );
                                } else if (underlyingbid_pctchng.indexOf("-") > -1) {
                                    underlyingbid_pctchng = Math.abs(
                                        _this.$js.formatNumber(underlyingbid_pctchng, 1, 0)
                                    );
                                } else if (
                                    underlyingbid_pctchng.indexOf("-") < 0 ||
                                    underlyingbid_pctchng.indexOf("+") < 0
                                ) {
                                    underlyingbid_pctchng = _this.$js.formatNumber(
                                        underlyingbid_pctchng,
                                        1,
                                        0
                                    );
                                }
                                if (data[value].BID_PCTCHNG.indexOf(",") > 0) {
                                    judgeP = parseInt(data[value].BID_PCTCHNG);
                                } else {
                                    judgeP = Number(data[value].BID_PCTCHNG);
                                }
                                if (data[value].BID_PCTCHNG !== "-" && judgeP >= 0) {
                                    left_num = true
                                } else if (data[value].BID_PCTCHNG !== "-" && judgeP < 0) {
                                    left_num = false
                                }

                                if (data[value].underlyingbid_pctchng.indexOf(",") > 0) {
                                    judgeUp = parseInt(data[value].underlyingbid_pctchng);
                                } else {
                                    judgeUp = Number(data[value].underlyingbid_pctchng);
                                }
                                if (data[value].underlyingbid_pctchng && judgeUp >= 0) {
                                    right_num = true
                                } else if (data[value].underlyingbid_pctchng && judgeUp < 0) {
                                    right_num = false
                                }
                                arrSun[index] = {
                                    dsply_nmll: data[value].dsply_nmll,
                                    BID_PCTCHNG: BID_PCTCHNG + '%',
                                    BID_NETCHNG: underlyingbid_pctchng + '%',
                                    left_num: left_num,
                                    right_num: right_num,
                                    underlying_ticker: data[value].underlying_ticker,
                                    ric: data[value].ric,
                                    underlying_ric: data[value].underlying_ric
                                }
                            });
                            this.dayDataArr = arrSun

                        }

                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
            } else {
                $dayData.eq(Number(index.replace(/[^0-9]/gi, "")) - 1).hide();
            }
        },
        tooltipRic: function (data) {
            let liRic
            let _this = this
            $.each(this.ricM, function (index, value) {
                liRic = data[value].dsply_nmll + " (" + data[value].ticker + "): " + data[value].issuer_name.replace("MACQ", "Macquarie");
                _this.tooltipArr.push({ name: liRic, ric: value, dsply_nmll: data[value].dsply_nmll })
            })
        },
        toTooltipArr: function (date) {
            localStorage.setItem("detailsRic", date.ric);
            let routes = this.$router.resolve({
                path: "/my/WarrantDetails",
                query: { dsplay_nmll: date.dsply_nmll }
            });
            location.href = routes.href;
        },
        seekAssignment: function () {
            if (!this.ifClick) {
                const Val = $('#matrix_input_0').val()
                const NewJoinRIC = this.JoinRIC.filter((item) => {
                    return Val.toLowerCase() === item.dsply_nmll.toLowerCase() || Val.toLowerCase() === item.ticker.toLowerCase() || Val.toLowerCase() === item.underlying_name.toLowerCase()
                })
                if (NewJoinRIC.length !== 0) {
                    this.valueLi = NewJoinRIC[0].ric
                    this.judgeLi = NewJoinRIC[0].judge
                    this.dsplay_nmll = NewJoinRIC[0].dsply_nmll
                }
            }
            if (this.valueLi) {
                $(".index-matrix-ric0").hide();
                if (this.judgeLi == 'true') {
                    localStorage.setItem("underlyingRic", this.valueLi);
                    let routes = this.$router.resolve({
                        path: "/my/UnderlyingChart",
                        query: { dsplay_nmll: this.dsplay_nmll }
                    });
                    location.href = routes.href;
                    this.ifClick = false
                } else {
                    localStorage.setItem("detailsRic", this.valueLi);
                    let routes = this.$router.resolve({
                        path: "/my/WarrantDetails",
                        query: { dsplay_nmll: this.dsplay_nmll }
                    });
                    location.href = routes.href;
                    this.ifClick = false
                }
            } else {
                $("#bannerModal").modal("show");
            }
        },
        matrixInput: function (lidate) {
            let then = this;
            let OibV
            $("#matrix_input_0").on("input", function () {
                //如果输入空格自动删除
                var inputVal = $(this).val();

                inputVal = $(this)
                    .val()
                    .replace(" ", "");
                if (inputVal) {
                    $('.tooltipUl').hide()
                    $(".index-matrix-ric0").show();
                    then.matrixAssignment(
                        then.$js.filtration(lidate, inputVal),
                        inputVal,
                        "index-matrix-ric0",
                        "matrix_input_0",
                        0
                    );
                    OibV = inputVal
                } else if (inputVal.length === 0) {
                    $(".index-matrix-ric0").hide();
                    $(".index-matrix-ric0").empty();
                    $('.tooltipUl').show()
                }
            });
            $("#matrix_input_0").on("focus", function () {
                OibV = $(this).val();
                $(this).val('')
                $('.tooltipUl').show()
            }).on('blur', function () {
                if (OibV.length !== 0) {
                    $(".banner-name").hide();
                    $(".banner-index").show();
                    $('.tooltipUl').hide()
                }
                $(this).val(OibV)
            })
        },
        matrixCombobox: function (data) {
            var arrS = [
                "Select Warrant One",
                "Select Warrant Two",
                "Select Warrant Three",
                "Select Warrant Four"
            ];
            var arrRic = [];
            let then = this;
            $.each($(".index_input_box"), function (i, v) {
                then.$js.jqcombobox("#matrix_input_" + (i + 1));
                $("#matrix_input_" + (i + 1)).combobox({
                    select: function (event, ui) {
                        arrRic[i] = this.value;
                        $(".ui-widget_" + (i + 1)).find('input').blur()
                    }
                });
                then.$js.clickEliminate('.ui-widget_' + (i + 1))
                $(".ui-widget_" + (i + 1))
                    .find("input")
                    .attr("placeholder", arrS[i]);
                var ricTxt = "";
                ricTxt += '<option value=" ">' + arrS[i] + "</option>";
                for (var r = 0, max = data.length; r < max; r++) {
                    if (data[r].issuer_name === 'RHB') {
                        ricTxt += '<option value="' + data[r].ric + '">' + data[r].dsply_nmll + ' (' + data[r].ticker + ')' + '</option>'
                    }
                }
                $("#matrix_input_" + (i + 1)).append(ricTxt);
            });
            this.matrixShow(arrRic);
        },
        matrixShow: function (arr) {
            $(".index-content-button").click(function () {
                localStorage.setItem("priceMatrix", arr);
                location.href = "warrantmatrix";
            });
        },
        // 获取 Promote 数据
        getPromote: function (data) {
            this.promote_img = data[0].image
            this.promote_http = data[0].hyperlink
            $('#HomePage').modal('show')
        },
        // 改 iframe的跳转地址
        alterIframe: function () {
            $("#ifr-grid").show();
            // $('.tgme_container_box').css({
            //   'width': ($('#ifr').width() - 19) + 'px'
            // })
        },
        //获取 focus数据
        getFocusDate: function () {
            let _this = this;
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedWarrantInFocus",
                data: { token: "webkey" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        _this.Focus = data.text[0]
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // banner
        // input框事件
        InputIncident: function () {
            $(".banner-con").mouseenter(function () {
                $(".banner-name").hide();
                $(".banner-index").show();
                if ($(".index-matrix-ric0").val()) {
                    $(".index-matrix-ric0").show();
                }
            });
            $(".banner-text-n").mouseleave(function () {
                if ($("#matrix_input_0").val().length === 0) {
                    $(".banner-name").show();
                    $(".banner-index").hide();
                } else {
                    $(".banner-name").hide();
                    $(".banner-index").show();
                }
                $(".index-matrix-ric0").hide();
            });
        },
        gadgetMenuToggle: function (index) {
            $('.mainContent').find('.li_box').eq(index).find('.head').find('ol').toggle()
        },
        // 点击显示相应页面数据
        componentsShow: function (data, index) {
            this.gadgetDataShow[index] = data
            this.$forceUpdate()
            localStorage.removeItem("rhbHome")
            localStorage.setItem("rhbHome", JSON.stringify(this.gadgetDataShow));
        },
        // 底部导航栏箭头
        navArrows: function () {
            let _this = this
            let navBox = $('.navMenu2Box').width(),
                widgetLiNum, widgetNum
            if (navBox > 540) {
                widgetNum = (navBox / 4) * 5
                widgetLiNum = (navBox / 4)
                this.navPlaceIf = 1
            } else if (navBox <= 540) {
                widgetNum = (navBox / 3) * 5
                widgetLiNum = (navBox / 3)
                this.navPlaceIf = 2
                if (navBox <= 370) {
                    this.navPlaceIf = 3
                    widgetNum = (navBox / 2) * 5
                    widgetLiNum = (navBox / 2)
                }
            }
            $('.navMenu2Box').find('.navMenu2').width(widgetNum)
            $('.navMenu2Box').find('li').width(widgetLiNum)
            $(window).resize(function () {
                navBox = $('.navMenu2Box').width()
                if (navBox > 540) {
                    widgetNum = (navBox / 4) * 5
                    widgetLiNum = (navBox / 4)
                    _this.navPlaceIf = 1
                } else if (navBox <= 540) {
                    widgetNum = (navBox / 3) * 5
                    widgetLiNum = (navBox / 3)
                    _this.navPlaceIf = 2
                    if (navBox <= 370) {
                        _this.navPlaceIf = 3
                        widgetNum = (navBox / 2) * 5
                        widgetLiNum = (navBox / 2)
                    }
                }
                $('.navMenu2Box').find('.navMenu2').width(widgetNum)
                $('.navMenu2Box').find('li').width(widgetLiNum)
                if (_this.navArrowsIf) {
                    $('.navMenu2Box').find('.navMenu2').css({
                        'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() + 'px, 0px, 0px)'
                    })
                }

            })
            // $(window).scroll(function() {
            //     if ($(window).scrollTop() + $(window).height() == $(document).height()) {
            //         $('.page-footer').css({
            //             'margin-bottom': $('.navMenu2Box').outerHeight(true)
            //         })
            //     } else {
            //         $('.page-footer').css({
            //             'margin-bottom': 0
            //         })
            //     }
            // })
        },
        arrowsLeft: function () {
            this.navArrowsIf = false
            if (this.navPlaceIf == 1) {
                this.num = 0
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * 0 + 'px, 0px, 0px)'
                })
            } else if (this.navPlaceIf == 2) {
                if (this.num <= 2 && this.num > 0) {
                    this.num--
                }
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * this.num + 'px, 0px, 0px)'
                })
            } else if (this.navPlaceIf == 3) {
                if (this.num <= 3 && this.num > 0) {
                    this.num--
                }
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * this.num + 'px, 0px, 0px)'
                })
            }
        },
        arrowsRight: function () {
            $('.arrowsLeft').show()
            this.navArrowsIf = true
            if (this.navPlaceIf == 1) {
                this.num = 1
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * 1 + 'px, 0px, 0px)'
                })
            } else if (this.navPlaceIf == 2) {
                if (this.num <= 2) {
                    this.num++
                }
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * this.num + 'px, 0px, 0px)'
                })
            } else if (this.navPlaceIf == 3) {
                if (this.num <= 3) {
                    this.num++
                }
                $('.navMenu2Box').find('.navMenu2').css({
                    'transform': 'translate3d(-' + $('.navMenu2Box').find('li').width() * this.num + 'px, 0px, 0px)'
                })
            }

        }
    },
    components: {
        Day,
        Telegram,
        ShoutOut,
        Matrix,
        HK,
        Movers,
        New,
        Expired
    }
};