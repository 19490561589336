export default {
  name: "Matrix",
  created() {
    this.$nextTick(function() {
      this.GetUnderlyingList();
      this.$js.inputEmpty(".search");
    });
  },
  mounted() {},
  data() {
    return {
      // list ric的数据
      ricData: [],
      ricDataDataDackups: [],
      search: "",
      ifSearchUl: false,
      // 列表数据
      MatrixData: [],
      //  转换之后的数据
      theirdata: [
        {
          name: "Exercise Price",
          price: ""
        },
        {
          name: "Exercise Ratio",
          price: ""
        },
        {
          name: "Expiry",
          price: ""
        },
        {
          name: "Effective Gearing",
          price: ""
        }
      ],
      ticker: "",
      underlyingRic: "",
      dsply_nmll: "",
      dataRic: "",
      dsplyRic: "",
      livematrix: [],
      publishTime: "",
      issuer_mibb: false,
      MAinput: ""
    };
  },
  props: ["ricM", "MAindex"],
  computed: {},
  watch: {},
  methods: {
    // 获取 Daily Highlights ric 数据
    GetDeployedWarrantOfTheDay: function() {
      let ricArr = [];
      $.ajax({
        type: "post",
        url: this.$js.webservice + "GetDeployedWarrantOfTheDay",
        data: { token: "webkey" },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            this.search = `(${data.text[0].underlying_ticker}) (${data.text[0].underlying_code}) ${data.text[0].underlying_name}`;
            this.GetRICListByUnderlying(data.text[0].underlying_name);
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },
    // 获取 underlying ric
    GetUnderlyingList: function() {
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetScreenerData",
        data: { token: "webkey", init: "1" },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            this.ricData = data.underlyings;
            this.ricDataDataDackups = data.underlyings;
            this.GetDeployedWarrantOfTheDay();
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },
    // 获取 underlying 的数据
    GetRICListByUnderlying: function(underlying, ifv) {
      let _this = this;
      let arrRic = [];
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICListByUnderlying",
        data: { token: "webkey", issuers: "RHB", underlying: underlying },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            $.each(data.ric, function(i, c) {
              arrRic.push(c.ric);
            });
            this.getRicdatas(arrRic, ifv);
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },
    /*获取当前ric数据*/
    getRicdatas: function(ric, ifv) {
      this.MatrixData = [];
      let _this = this;
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetMarketData",
        data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            $.each(ric, function(i, c) {
              _this.MatrixData.push(data[c]);
            });
            if (ifv) {
              $(".tspl_table" + this.MAindex).show("slow");
              $(".switch_table" + this.MAindex).hide("slow");
            } else {
              this.GetPriceMatrixData(this.MatrixData[0].ric);
            }
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },
    /*获取当前ric数据*/
    GetPriceMatrixData: function(ric) {
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetPriceMatrixData",
        data: { token: "webkey", ric: ric },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            this.theirdata[0].price =
              data.ric_data.exercise_price !== "N/A"
                ? this.$js.curreryRetrun(data.ric_data.underlying_curr) +
                  " " +
                  this.$js.formatNumber(data.ric_data.exercise_price, 4, 0)
                : "N/A";
            this.theirdata[1].price = this.$js.formatNumber(
              data.ric_data.conv_ratio,
              4,
              0
            );
            this.theirdata[2].price = data.ric_data.maturity;
            this.theirdata[3].price =
              data.ric_data.effective_gearing !== "N/A"
                ? this.$js.formatNumber(data.ric_data.effective_gearing, 1, 0) +
                  " x"
                : "N/A";
            this.ticker = data.ric_data.underlying_ticker;
            this.underlyingRic = data.ric_data.underlying_ric;
            this.dsply_nmll = data.ric_data.dsply_nmll;
            this.dataRic = data.ric_data.ric;
            if (
              data.reliable_livematrix == 0 &&
              this.$js.isRHB(data.ric_data.issuer_name)
            ) {
              this.issuer_mibb = true;
              $("#reliableLivematrix").modal("show");
            } else {
              this.issuer_mibb = false;
              this.livematrix = this.isRepeat(data.livematrix);
              this.dsplyRic = data.ric_data.ric;
              this.publishTime =
                data.livematrix.length === 0
                  ? ""
                  : data.livematrix[0].publish_time;
            }
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    },
    // 搜索相应数据
    searchInput: function(e) {
      this.MAinput = e.target.value;
      this.search = e.target.value;
      if (e.target.value.length == 0) {
        this.ricData = this.ricDataDataDackups;
        this.ifSearchUl = false;
      } else {
        this.ricData = this.ricDataDataDackups.filter(item => {
          let name = `(${item.underlying_ticker}) (${item.underlying_code}) ${item.underlying_name}`;
          return (
            name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 ||
            e.target.value.toLowerCase().indexOf(name.toLowerCase()) >= 0
          );
        });
        this.ifSearchUl = true;
      }
    },
    searchClick: function(data) {
      this.search = `(${data.underlying_ticker}) (${data.underlying_code}) ${data.underlying_name}`;
      this.GetRICListByUnderlying(data.underlying_name, true);
      this.MAinput = "";
      this.ifSearchUl = false;
    },
    // 转换显示模式
    switchPattern: function(ric) {
      this.GetPriceMatrixData(ric);
      $(".tspl_table" + this.MAindex).hide("slow");
      $(".switch_table" + this.MAindex).show("slow");
    },
    // 判断数组下一个是否与上一个相等
    isRepeat: function(arr) {
      let hash = {};
      let ifv = false;
      for (let i in arr) {
        if (hash[arr[i].bid]) {
          hash[arr[i].bid] = false;
        } else {
          if (i !== "0") ifv = !ifv;
        }
        arr[i].ifv = ifv;
        hash[arr[i].bid] = true;
      }
      return arr;
    },
    //跳转到相应页面
    toRelevantPage: function(judge, name, ric) {
      if (ric) {
        if (judge) {
          localStorage.setItem("detailsRic", ric);
          this.$router.push({
            path: "/my/WarrantDetails",
            query: { dsplay_nmll: name }
          });
        } else {
          localStorage.setItem("underlyingRic", ric);
          this.$router.push({
            path: "/my/UnderlyingChart",
            params: { dsplay_nmll: name }
          });
        }
      }
    }
  }
};
