export default {
    name: "Day",
    created() {},
    mounted() {
        this.$nextTick(function() {})
    },
    data() {
        return {};
    },
    props: ['dayDataArr', 'dayDataArrIf'],
    methods: {
        //跳转到WarrantDetails
        toDetails: function(judge, num, ric) {
            if (judge) {
                localStorage.setItem("detailsRic", ric);
                let routes = this.$router.resolve({
                    path: "/my/WarrantDetails",
                    query: { dsplay_nmll: num }
                });
                location.href = routes.href;
            } else {
                localStorage.setItem("underlyingRic", ric);
                let routes = this.$router.resolve({
                    path: "/my/UnderlyingChart",
                    query: { dsplay_nmll: num }
                });
                location.href = routes.href;
            }

        },
    }
};