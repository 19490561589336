export default {
    name: "Expired",
    created() {
        this.$nextTick(function() {
            this.getRicList()
        });
    },
    mounted() {

    },
    data() {
        return {
            ExpiredDataNav: [],
            ExpiredDataNavText: '',
            ExpiredDataNavSun: [],
            ExpiredData: []
        };
    },
    props: ['Eindex'],
    computed: {

    },
    watch: {

    },
    methods: {
        getRicList() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetRICList",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        this.GetDeployedExpireWarrant(data.ric);
                    }

                }
            })
        },
        // 获取 Expired Warrant ric 数据
        GetDeployedExpireWarrant: function(ric) {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedExpireWarrant",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let _this = this
                        this.ExpiredDataNavSun = data.text
                        $.each(data.text, function(i, c) {
                            _this.ExpiredDataNav.push(i)
                        })
                        this.ExpiredDataNavText = this.$js.formatYearDate(this.ExpiredDataNav[0])
                        this.ExpiredData = data.text[this.ExpiredDataNav[0]]
                        $.each(this.ExpiredData, function(i, n) {
                            $.each(ric, function(j, c) {
                                if (n.name == c.dsply_nmll) {
                                    n.ric = c.ric
                                }
                            })
                        })
                    }
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
        ExpiredDataNavShow: function() {
            $('.Expired' + this.Eindex).find('.Expirednav').toggle()
        },
        ExpiredDataNavData: function(data) {
            this.ExpiredDataNavText = this.$js.formatYearDate(data)
            this.ExpiredData = this.ExpiredDataNavSun[data]
            $('.Expired' + this.Eindex).find('.Expirednav').hide()
        },
        //跳转到相应页面
        toRelevantPage: function(name, ric) {
            if (ric) {
                localStorage.setItem("detailsRic", ric);
                this.$router.push({
                    path: "/my/WarrantDetails",
                    query: { 'dsplay_nmll': name }
                });
            }

        },
    }
};