export default {
    name: "Telegram",
    created() {
        this.$nextTick(function() {

        });
    },
    mounted() {
        this.$nextTick(function() {

        })
    },
    data() {
        return {

        };
    },
    props: ['telegram'],
    methods: {

    }
};