export default {
    name: "ShoutOut",
    created() {
        this.$nextTick(function() {

        });
    },
    mounted() {
        this.$nextTick(function() {

        })
    },
    data() {
        return {

        };
    },
    props: ['shoutQutLiOne'],
    methods: {
        gTiem: function(ds) {
            //TODO: remove this
            var nds = [];
            var dds = ds.split("-");
            var MMM = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            if (dds.length === 3) {
                var dd = Number(dds[2]);
                var mm = MMM[Number(dds[1]) - 1];
                var yyyy = dds[0].substring(4 - 2, 4);
                nds[0] = dd;
                nds[1] = mm;
                nds[2] = yyyy;
            }
            return nds;
        },

        getLiToShoutoutwarrants: function(v) {
            localStorage.setItem("ShoutoutwarrantsLi", v);
            let routes = this.$router.resolve({
                path: "/my/WarrantShout-Out"
            });
            location.href = routes.href;
        },
    }
};