import Chart from "../Chart/Chart.vue";
export default {
    name: "FTSE",
    created () {
        this.$nextTick(function () {
            this.GetHKUnderlyingList()

        });
    },
    mounted () {

    },
    props: ['Findex', 'ricM'],
    data () {
        return {
            FTSEBursaMalaysiaKLCIIndexData: [],
            chartNmae: '',
            ricName: '',
            Finput: '',
            search: '',
            ifSearchUl: false,
            ricData: [],
            ricArr: [],
            ricDataDataDackups: [],

        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        GetHKUnderlyingList: function () {
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetHKUnderlyingList",
                data: { token: "webkey", issuers: 'RHB' },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        console.log(data);
                        this.ricData = data.underlying_name
                        this.ricDataDataDackups = data.underlying_name
                        this.ricArr = data.underlying_name[0].underlying_ric
                        this.GetRICListByUnderlying(data.underlying_name[0].underlying_name)


                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying 的数据
        GetRICListByUnderlying: function (underlying) {
            let _this = this
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetRICListByUnderlying",
                data: { token: "webkey", issuers: 'RHB', underlying: underlying },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        let arr = []
                        $.each(data.ric, function (i, c) {
                            arr.push(c.ric)
                        })
                        this.getRicdatas(arr)

                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        /*获取当前ric数据*/
        getRicdatas: function (ric) {

            this.FTSEBursaMalaysiaKLCIIndexData = []
            let _this = this
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        let _this = this
                        $.each(ric, function (i, c) {
                            _this.ricName = `(${data[c].underlying_ticker}) (${data[c].underlying_code}) ${data[c].underlying_name}`
                            _this.chartNmae = data[c].underlying_ticker
                            _this.FTSEBursaMalaysiaKLCIIndexData.push(data[c])
                        })
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 underlying ric
        GetUnderlyingList: function () {
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetUnderlyingList",
                data: { token: "webkey", issuers: 'RHB' },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.ricData = data.underlying_name
                        this.ricDataDataDackups = data.underlying_name
                        this.GetDeployedWarrantOfTheDay()
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        //跳转到相应页面
        toRelevantPage: function (name, ric) {
            if (ric) {
                localStorage.setItem("detailsRic", ric);
                this.$router.push({
                    path: "/my/WarrantDetails",
                    query: { 'dsplay_nmll': name }
                });
            }

        },
        // 搜索相应数据
        searchInput: function (e) {
            this.search = e.target.value
            if (e.target.value.length == 0) {
                this.ricData = this.ricDataDataDackups
                this.ifSearchUl = false
            } else {
                this.ricData = this.ricDataDataDackups.filter((item) => {
                    let name = `(${item.underlying_ticker}) (${item.underlying_code}) ${item.underlying_name}`
                    return name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 || e.target.value.toLowerCase().indexOf(name.toLowerCase()) >= 0
                })
                this.ifSearchUl = true
            }
        },
        searchClick: function (data) {
            this.ricArr = data.underlying_ric
            this.search = `(${data.underlying_ticker}) (${data.underlying_code}) ${data.underlying_name}`
            this.GetRICListByUnderlying(data.underlying_name, true)
            this.ifSearchUl = false
        },
    },
    components: {
        Chart
    }
};