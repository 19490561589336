export default {
    name: "New",
    created() {
        this.$nextTick(function() {
            this.getRicList()
        });
    },
    mounted() {

    },
    data() {
        return {
            NewDataNav: [],
            NewDataNavText: '',
            NewDataNavSun: [],
            NewData: []
        };
    },
    props: ['Nindex'],
    computed: {

    },
    watch: {

    },
    methods: {
        getRicList() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetRICList",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        this.GetDeployedNewWarran(data.ric);
                    }

                }
            })
        },
        // 获取 New Warrant ric 数据
        GetDeployedNewWarran: function(ric) {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetDeployedNewWarrant",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let _this = this
                        this.NewDataNavSun = data.text
                        $.each(data.text, function(i, c) {
                            _this.NewDataNav.push(i)
                        })
                        this.NewDataNavText = this.$js.formatYearDate(this.NewDataNav[0])
                        this.NewData = data.text[this.NewDataNav[0]]
                        $.each(this.NewData, function(i, n) {
                            $.each(ric, function(j, c) {
                                if (n.name == c.dsply_nmll) {
                                    n.ric = c.ric
                                }
                            })
                        })

                    }
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
        NewDataNavShow: function() {
            $('.New' + this.Nindex).find('.Newnav').toggle()
        },
        NewDataNavData: function(data) {
            this.NewDataNavText = this.$js.formatYearDate(data)
            this.NewData = this.NewDataNavSun[data]
            $('.New' + this.Nindex).find('.Newnav').hide()
        },
        //跳转到相应页面
        toRelevantPage: function(name, ric) {
            if (ric) {
                localStorage.setItem("detailsRic", ric);
                this.$router.push({
                    path: "/my/WarrantDetails",
                    query: { 'dsplay_nmll': name }
                });
            }

        },
    }
};