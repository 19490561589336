export default {
    name: "Chart",
    created () { },
    mounted () {
        this.$nextTick(function () {

        });
    },
    props: [
        'chartNmae',
        'Cindex',
        'ricArr'
    ],
    data () {
        return {

        };
    },
    computed: {

    },
    watch: {
        ricArr: function () {
            this.GetChartData()
        }
    },
    methods: {
        GetChartData: function () {
            $.ajax({
                type: "post",
                url: this.$js.webservice + "GetChartData",
                data: { token: "webkey", type: "1", period: "m", ric: this.ricArr },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        var fullchartArr = [];
                        var chartArr = []
                        $.each(data.underlyingDataList, (i, c) => {
                            var arr = [];
                            arr.push(this.$js.changeToLocalTime(c.date));
                            arr.push(c.open);
                            arr.push(c.high);
                            arr.push(c.low);
                            arr.push(c.close);
                            arr.push(c.volume);
                            fullchartArr.push(arr);
                            var currentdate = new Date().getTime();
                            var jsondate = new Date(c.date).getTime();
                            var diff = 1000 * 60 * 60 * 24 * 30;
                            if (currentdate - diff < jsondate) chartArr.push(arr);
                        });
                        this.ChartLoad(chartArr, fullchartArr);

                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 显示chart图
        ChartLoad (chartArr, fullchartArr) {
            $("#HomeChart" + this.Cindex).empty();
            let _this = this;
            anychart.onDocumentReady(function () {
                // create data table on loaded data
                var dataTable = anychart.data.table();
                dataTable.addData(fullchartArr);
                var candlestickMapping = dataTable.mapAs({
                    open: 1,
                    high: 2,
                    low: 3,
                    close: 4
                });


                var scrollerData = anychart.data.table();
                scrollerData.addData(chartArr);
                var scollerMapping = scrollerData.mapAs({
                    open: 1,
                    high: 2,
                    low: 3,
                    close: 4
                });

                // map data for volume series
                var valueMapping = dataTable.mapAs({
                    value: 5
                });

                // create stock chart
                var chart = anychart.stock();
                chart.padding(20, 30, 20, 80)
                // chart.xScale('scatter');

                var candlestickPlot = chart.plot(0);
                var candlestickSeries = candlestickPlot.candlestick(candlestickMapping);
                candlestickPlot.xGrid().enabled(true);
                candlestickPlot.yGrid().enabled(true);
                candlestickPlot.xAxis().showHelperLabel(false);
                candlestickPlot
                    .xAxis()
                    .labels()
                    .enabled(false);
                candlestickPlot.xAxis().height(0);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .format(function () {
                        return _this.$js.formatNumber(this.value, 3, 0);
                    });

                candlestickSeries.name(_this.chartNmae);
                candlestickSeries.risingFill("#009900");
                candlestickSeries.fallingFill("#FF0000");
                candlestickSeries.legendItem().iconType("risingfalling");

                var candlestickSeriestooltip = candlestickSeries.tooltip();
                candlestickSeriestooltip.enabled(true);

                candlestickSeriestooltip.format(function (e) {
                    var close = this.close;
                    var open = this.open;
                    var high = this.high;
                    var low = this.low;
                    return (
                        "Close: " +
                        _this.$js.formatNumber(close, 3, 0) +
                        "\nOpen: " +
                        _this.$js.formatNumber(open, 3, 0) +
                        "\nHigh: " +
                        _this.$js.formatNumber(high, 3, 0) +
                        "\nLow: " +
                        _this.$js.formatNumber(low, 3, 0)
                    );
                });
                // create stock end
                candlestickPlot.legend().useHtml(true);

                // configure the format of legend items
                candlestickPlot.legend().itemsFormat(function () {
                    var series = this.series;

                    if (series.getType() == "candlestick") {
                        return (
                            series.name() +
                            ": (O: " +
                            _this.$js.formatNumber(this.open, 3, 0) +
                            ", H: " +
                            _this.$js.formatNumber(this.high, 3, 0) +
                            ", L: " +
                            _this.$js.formatNumber(this.low, 3, 0) +
                            ", C: " +
                            _this.$js.formatNumber(this.close, 3, 0) +
                            ")"
                        );
                    } else if (series.getType() == "line") {
                        return (
                            series.name() + ": " + _this.$js.formatNumber(this.value, 3, 0)
                        );
                    } else if (series.getType() == "range-area") {
                        return (
                            series.name() +
                            ": (H: " +
                            _this.$js.formatNumber(this.high, 3, 0) +
                            ", H: " +
                            _this.$js.formatNumber(this.high, 3, 0) +
                            ")"
                        );
                    } else if (series.getType() == "spline") {
                        return (
                            series.name() + ": " + _this.$js.formatNumber(this.value, 3, 0)
                        );
                    }
                });

                // create scroller series
                // chart.scroller().area(candlestickMapping);
                // chart.scroller().height("20px");
                chart.selectRange(_this.getPassFormatDate(), _this.getNowFormatDate());
                // chart.scroller().enabled(true)
                // chart.scroller().orientation('top')
                // chart.scroller().minHeight(35)
                // chart.scroller().thumbs().autoHide(true)
                chart.scroller().selectedFill('#ffc600', '0.3')
                var xScroller = chart.scroller();
                xScroller.orientation('top');
                xScroller.enabled(false);
                // enable chart scroll
                var listener = function (e) {
                    return true;
                };
                chart.listen("selectedrangechangestart", listener);
                chart.listen("selectedrangebeforechange", listener);
                chart.listen("selectedrangechange", listener);
                chart.listen("selectedrangechangefinish", listener);

                // set container id for the chart
                chart.container("HomeChart" + _this.Cindex);

                // initiate chart drawing
                chart.draw();
                $(".anychart-credits").remove();
                $(".anychart-control").remove();

            });
        },
        getNowFormatDate () {
            var date = new Date();
            var seperator1 = "-";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        //1m
        getPassFormatDate () {
            var nowDate = new Date();
            var date = new Date(nowDate);
            date.setDate(date.getDate() - 30);
            var seperator1 = "-";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;

        },
    }
};